import dayjs from 'dayjs';
import debounce from 'just-debounce';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatPrice } from '../lib/helpers';
import {
  ecommerceCheckoutAddingAdditionalProduct,
  ecommerceCheckoutFillingContacts,
  ecommerceCheckoutSelectDeliveryOption,
  ecommerceCheckoutSelectingDelivery,
  ecommerceCheckoutSelectingPayment,
  ecommerceCheckoutSelectPaymentOption,
  ecommerceCheckoutStart,
  ecommerceCheckoutSubmit,
} from '../lib/enhancedEcommerce';
import { timeout } from '../lib/timeout';
import { vkSendPaymentInfo } from '../lib/vkPixel';
import { disableScroll, enableScroll } from '../lib/scroll';
import { initStoreLocationMap } from '../lib/map';
import {
  applyZonePrices,
  DeliveryInterval,
  findDeliveryIntervalByValue,
  getAvailableDeliveryTimeIntervals,
  createDeliveryIntervals,
  isToday,
  managersWorkingHours,
} from '../lib/deliveryTime';
import { DeliveryMap } from '../lib/DeliveryMap';
import { deliveryZones } from '../lib/deliveryZones';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';
import { getAddress, getDeliveryZone } from '../api/geo';

type CartParams = {
  customPostcardPrice?: number;
  minimumOrderPrice?: number;
  exactTimeDeliveryPrice?: number;
  pickupIntervals?: DeliveryInterval[];
  courierIntervals?: DeliveryInterval[];
  mapDeliveryZones?: Object;
};

dayjs.extend(customParseFormat);
const holidays = [dayjs().month(2).date(6), dayjs().month(2).date(7), dayjs().month(2).date(8)];
const forbiddenDates = [
  // dayjs().month(2).date(6),
  // dayjs().month(2).date(7),
  // dayjs().month(2).date(8),
];

export function cart(Alpine) {
  Alpine.data('cart', cartComponent);

  function cartComponent({
    customPostcardPrice = 0,
    minimumOrderPrice = 0,
    exactTimeDeliveryPrice = 0,
    pickupIntervals = [],
    courierIntervals = [],
    mapDeliveryZones = {},
  }: CartParams = {}) {
    return {
      minimumOrderPrice,
      exactTimeDeliveryPrice,
      errors: {},
      quickOrderErrors: {},
      isSubmitting: false,
      isSubmittingQuickOrder: false,
      courierAddress: this.$store.checkout.form.courierAddress,
      deliveryZone: null,
      _courierDeliveryPrice: 0,
      postcardDialogIsShown: false,
      pickupMapDialogIsShown: false,
      isCourierMapDialogShown: false,
      isPickupMapInited: false,
      deliveryMap: null,
      isQuickOrderDialogShown: false,
      hasStep2Started: false,
      hasStep3Started: false,
      hasStep4Started: false,
      hasStep5Started: false,

      get deliveryPrice() {
        let price = 0;
        const type = this.$store.checkout.form.delivery;

        if (type === 'pickup') {
          const time = this.$store.checkout.form.pickupTime;
          const selectedInterval = pickupIntervals.find((item) => item.value === time);
          price += selectedInterval?.price ?? 0;
        } else if (type === 'courier') {
          if (this.deliveryZone) {
            const interval = this.$store.checkout.form.courierInterval;
            const courierPrice = this.deliveryZone.prices?.[this.$store.checkout.form.courierType];
            price = courierPrice ? courierPrice : this.deliveryZone.price;
            const selectedInterval = this.$store.checkout.courierIntervals.find(
              (item) => item.value === interval
            );
            price += selectedInterval?.price ?? 0;
          }

          if (this.$store.checkout.form.isCourierExactTime) {
            price += exactTimeDeliveryPrice;
          }
        }

        return price;
      },
      get formattedDeliveryPrice() {
        if (this.$store.checkout.form.delivery !== 'pickup' && this.deliveryPrice <= 0)
          return 'После согласования';
        return formatPrice(this.deliveryPrice);
      },
      get isOnlinePayble() {
        return (
          this.$store.checkout.form.payment === 'card' &&
          !(this.$store.checkout.form.delivery !== 'pickup' && this.deliveryPrice <= 0)
        );
      },
      get additionalCosts() {
        let price = 0;
        if (this.$store.checkout.form.postcardType === 'custom') {
          price += customPostcardPrice;
        }
        return price;
      },
      get total() {
        return this.$store.cart.price + this.deliveryPrice + this.additionalCosts;
      },
      get oldPriceTotal() {
        return this.$store.cart.oldPrice + this.deliveryPrice + this.additionalCosts;
      },
      get clientsTotal() {
        return this.$store.cart.priceClients + this.deliveryPrice + this.additionalCosts;
      },
      get hasClientsTotal() {
        return this.clientsTotal < this.total;
      },
      get deliveryDate() {
        return this.$store.checkout.form.delivery === 'pickup'
          ? this.$store.checkout.form.pickupDate
          : this.$store.checkout.form.courierDate;
      },
      get isForbiddenDate() {
        const date = dayjs(this.deliveryDate, 'DD.MM.YYYY');
        return forbiddenDates.some((forbidden) => date.isSame(forbidden, 'day'));
      },
      get isUnderMinPrice() {
        const date = dayjs(this.deliveryDate, 'DD.MM.YYYY');
        const isHoliday = holidays.some((holiday) => date.isSame(holiday, 'day'));

        return (
          this.minimumOrderPrice > 0 &&
          this.$store.cart.price <= this.minimumOrderPrice &&
          isHoliday
        );
      },
      get isOrderValid() {
        return !this.isUnderMinPrice && !this.isForbiddenDate;
      },
      get isServiceCourierSelected() {
        return (
          (this.$store.checkout.form.delivery === 'courier' ||
            this.$store.checkout.form.delivery === 'unknown') &&
          this.$store.checkout.form.courierType === 'service_courier'
        );
      },
      get canUsePaymentWithCardUponReceipt() {
        return (
          this.$store.checkout.form.recipientType === 'me' &&
          (this.$store.checkout.form.delivery === 'courier' ||
            this.$store.checkout.form.delivery === 'unknown') &&
          !this.isServiceCourierSelected
        );
      },
      get canUsePaymentWithCash() {
        return this.$store.checkout.form.recipientType === 'me' && !this.isServiceCourierSelected;
      },
      get canUsePaymentWithOther() {
        return !this.isServiceCourierSelected;
      },
      init() {
        console.log('Initializing cart');

        // const getNow = () => dayjs('2023-11-29 09:10');
        const getNow = () => dayjs();

        dynamicImportWithRetry(() => import('../lib/gsap')).then(({ gsap }) => {
          gsap.to('.cart-panel', {
            scrollTrigger: {
              trigger: '.cart__details',
              start: 'top bottom',
              toggleActions: 'play play reverse reverse',
              // markers: true,
            },
            yPercent: 100,
            ease: 'easeInOutQuart',
            duration: 0.5,
          });
        });

        ecommerceCheckoutStart(this.$store.cart.items);
        this.initAddressField().then();

        this.$store.checkout.courierIntervals = courierIntervals;

        // sanitize memorized dates
        const pickupDate =
          this.$store.checkout.form.pickupDate.length > 0
            ? dayjs(this.$store.checkout.form.pickupDate, 'DD.MM.YYYY')
            : null;
        const courierDate =
          this.$store.checkout.form.courierDate.length > 0
            ? dayjs(this.$store.checkout.form.courierDate, 'DD.MM.YYYY')
            : null;
        if (pickupDate && pickupDate.isBefore(getNow(), 'day')) {
          this.$store.checkout.form.pickupDate = '';
          this.$store.checkout.form.pickupTime = '';
        }
        if (courierDate && courierDate.isBefore(getNow(), 'day')) {
          this.$store.checkout.form.courierDate = '';
          this.$store.checkout.form.courierInterval = '';
          this.$store.checkout.form.courierPreferredTime = '';
        }
        if (this.$store.checkout.form.courierInterval !== 'exact') {
          this.$store.checkout.form.courierPreferredTime = '';
        }

        // update pickup time intervals
        const updatePickupIntervals = (selectedDate) => {
          if (!selectedDate) {
            this.$store.checkout.pickupIntervals = [];
            return;
          }
          this.$store.checkout.pickupIntervals = getAvailableDeliveryTimeIntervals(
            getNow(),
            dayjs(selectedDate, 'DD.MM.YYYY'),
            pickupIntervals,
            managersWorkingHours
          );
        };
        updatePickupIntervals(this.$store.checkout.form.pickupDate);
        this.$watch('$store.checkout.form.pickupDate', (selectedDate) =>
          updatePickupIntervals(selectedDate)
        );

        // update courier delivery intervals
        const updateCourierIntervals = (selectedDate, selectedZone) => {
          if (!selectedDate) {
            this.$store.checkout.courierIntervals = [];
            this.$store.checkout.courierPreferredTimeIntervals = [];
            return;
          }

          this.$store.checkout.courierIntervals = getAvailableDeliveryTimeIntervals(
            getNow(),
            dayjs(selectedDate, 'DD.MM.YYYY'),
            courierIntervals,
            managersWorkingHours
          );

          if (!selectedZone) return;
          const zone = deliveryZones.find((item) => item.name === selectedZone.zone);
          if (!zone) return;

          if (isToday(dayjs(selectedDate, 'DD.MM.YYYY'))) {
            this.$store.checkout.courierIntervals = applyZonePrices(
              this.$store.checkout.courierIntervals,
              zone,
              getNow()
            );
          }
        };
        this.$watch('deliveryZone', (selectedZone) =>
          updateCourierIntervals(this.$store.checkout.form.courierDate, selectedZone)
        );
        this.$watch('$store.checkout.form.courierDate', (selectedDate) =>
          updateCourierIntervals(selectedDate, this.deliveryZone)
        );

        const updatePreferredTimeIntervals = (selectedInterval) => {
          if (!selectedInterval) {
            this.$store.checkout.courierPreferredTimeIntervals = [];
            return;
          }
          const interval = findDeliveryIntervalByValue(
            this.$store.checkout.courierIntervals,
            selectedInterval
          );
          this.$store.checkout.courierPreferredTimeIntervals = createDeliveryIntervals(
            interval.from,
            interval.to
          );
        };
        updatePreferredTimeIntervals(this.$store.checkout.form.courierInterval);
        this.$watch('$store.checkout.form.courierInterval', (selectedInterval) => {
          updatePreferredTimeIntervals(selectedInterval);
        });

        this.$watch('$store.checkout.form.recipientType', (type) => {
          if (type !== 'other' && this.$store.checkout.form.delivery === 'unknown') {
            this.$store.checkout.form.delivery = 'courier';
          }

          if (type === 'other') {
            this.$store.checkout.form.payment = 'card';
          }
        });

        this.$watch('$store.cart.items', (newCart, oldCart) => {
          if (!this.hasStep2Started) {
            if (Object.keys(newCart).length > Object.keys(oldCart).length) {
              this.hasStep2Started = true;
              ecommerceCheckoutAddingAdditionalProduct(this.$store.cart.items);
            }
          }
        });

        this.$watch('$store.checkout.form.buyerName, $store.checkout.form.buyerPhone', () => {
          if (!this.hasStep3Started) {
            this.hasStep3Started = true;
            ecommerceCheckoutFillingContacts(this.$store.cart.items);
          }
        });

        this.$watch('$store.checkout.form.delivery', () => {
          if (!this.hasStep4Started) {
            this.hasStep4Started = true;
            ecommerceCheckoutSelectingDelivery(this.$store.cart.items);
          }
          ecommerceCheckoutSelectDeliveryOption(
            this.$store.checkout.form.delivery,
            this.$store.cart.items
          );
        });

        this.$watch('$store.checkout.form.payment', () => {
          if (!this.hasStep5Started) {
            this.hasStep5Started = true;
            ecommerceCheckoutSelectingPayment(this.$store.cart.items);
          }
          ecommerceCheckoutSelectPaymentOption(
            this.$store.checkout.form.payment,
            this.$store.cart.items
          );
          vkSendPaymentInfo();
        });

        this.$watch('postcardDialogIsShown', (isShown) => {
          if (isShown) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        const fetchDeliveryPrice = debounce(this.fetchDeliveryPrice.bind(this), 500);
        this.$watch('$store.checkout.form.courierAddress', async (address) => {
          this.courierAddress = address;
          fetchDeliveryPrice(address);
        });
        if (
          this.$store.checkout.form.delivery === 'courier' &&
          this.$store.checkout.form.courierAddress
        ) {
          fetchDeliveryPrice(this.$store.checkout.form.courierAddress);
        }
      },
      async initAddressField() {
        const $ = await dynamicImportWithRetry(() => import('jquery'));
        // @ts-ignore
        window.jQuery = $;
        // @ts-ignore
        window.$ = $;
        await dynamicImportWithRetry(() => import('suggestions-jquery'));

        await this.$nextTick();
        $(document.querySelector('input[name="dialogCourierAddress"]')).suggestions({
          token: wpParams.dadataToken,
          type: 'ADDRESS',
          hint: null,
          count: 6,
          onSelect: (suggestion) => {
            let { geo_lat, geo_lon } = suggestion.data;
            if (!geo_lon || !geo_lat) return;

            geo_lat = parseFloat(geo_lat);
            geo_lon = parseFloat(geo_lon);

            this.$store.checkout.form.courierAddressLat = geo_lat;
            this.$store.checkout.form.courierAddressLon = geo_lon;
            this.$store.checkout.form.courierAddress = suggestion.value;

            if (this.deliveryMap) {
              this.deliveryMap.updateCoordinates([geo_lat, geo_lon], true);
            }
          },
          onSelectNothing: () => {
            this.courierAddress = this.$store.checkout.form.courierAddress; // reset to last value
          },
        });
      },
      async fetchDeliveryPrice(address: string) {
        if (!address) {
          this.deliveryZone = null;
          return;
        }

        const zone = await getDeliveryZone(address);
        if (zone) {
          this.deliveryZone = zone;
        } else {
          this.deliveryZone = null;
        }
      },
      async openPickupMapDialog() {
        this.pickupMapDialogIsShown = true;

        if (!this.isPickupMapInited) {
          await this.$nextTick();
          await initStoreLocationMap(this.$refs.pickupMap);
          this.isPickupMapInited = true;
        }
      },
      closePickupMapDialog() {
        this.pickupMapDialogIsShown = false;
      },
      async openCourierMapDialog() {
        this.isCourierMapDialogShown = true;

        if (!this.deliveryMap) {
          await this.$nextTick();
          this.deliveryMap = new DeliveryMap(this.$refs.courierMap, mapDeliveryZones, {
            coordinates: [
              this.$store.checkout.form.courierAddressLat ?? 0,
              this.$store.checkout.form.courierAddressLon ?? 0,
            ],
            onLocationChange: async (lat, lon) => {
              const address = await getAddress(lat, lon);
              if (address) {
                this.$store.checkout.form.courierAddress = address;
                this.$store.checkout.form.courierAddressLat = lat;
                this.$store.checkout.form.courierAddressLon = lon;
              } else {
                this.$store.checkout.form.courierAddress = '';
                this.$store.checkout.form.courierAddressLat = 0;
                this.$store.checkout.form.courierAddressLon = 0;
              }
            },
          });
        }
      },
      closeCourierMapDialog() {
        this.isCourierMapDialogShown = false;
      },
      openQuickOrderDialog() {
        this.isQuickOrderDialogShown = true;
      },
      closeQuickOrderDialog() {
        this.isQuickOrderDialogShown = false;
        this.quickOrderErrors = {};
      },
      async onQuickOrderSubmit() {
        const validationResult = this.$store.checkout.validateQuickOrder();
        if (validationResult.isValid) {
          this.isSubmittingQuickOrder = true;
          ecommerceCheckoutSubmit(this.$store.cart.items);
          await timeout(400);
          this.$refs.form.submit();
          return;
        }

        this.quickOrderErrors = validationResult.errors;
      },
      async submit() {
        const validationResult = this.$store.checkout.validate();
        if (validationResult.isValid) {
          this.isSubmitting = true;
          ecommerceCheckoutSubmit(this.$store.cart.items);

          if ('yaCounter22556596' in window) {
            window.yaCounter22556596.reachGoal('cart__submit');
          }

          await timeout(400);
          this.$refs.form.submit();
          return;
        }

        this.errors = validationResult.errors;

        // scroll to the first input with error
        const firstElement = Object.keys(validationResult.errors)[0];
        if (firstElement) {
          // input can be hidden, so scroll to it's parent
          const scrollTo = document.querySelector(`[name="${firstElement}"]`)
            ?.parentElement as HTMLElement;
          if (scrollTo) {
            scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      },
    };
  }
}
